import 'react-chat-elements/dist/main.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import NotFoundPage from './pages/shared/NotFoundPage';
import Shell from './pages/shared/Shell';
import UnauthorizedPage from './pages/shared/UnauthorizedPage';
import routes from './services/RouteService';

function App() {
  return (
    <Routes>
      <Route element={<Shell />}>
        <Route path={routes.home.route} element={<Home />} />
      </Route>

      <Route path={routes.unauthorized.route} element={<UnauthorizedPage />} />
      <Route path={routes.notFound.route} element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
