import { useInterval } from 'ahooks';
import { Progress } from 'antd';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import AppColors from '../../config/AppColors';
import Logo from '../assets/Logo';

const NB_OF_INCREMENT = 3;
const LOADING_TIME = 500;
const INTERVAL_TIME = LOADING_TIME / NB_OF_INCREMENT;

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});
const inlineStyles = {
  progress: {
    marginTop: 20,
    width: 300,
  },
};

export default function PageLoader() {
  // State
  const [percent, setPercent] = useState(0);

  // Hooks
  const styles = useStyles();

  useInterval(() => {
    const incrementPercentage = 100 / NB_OF_INCREMENT;
    setPercent(percent + incrementPercentage);
  }, INTERVAL_TIME);

  return (
    <div className={styles.container}>
      <Logo />
      <Progress percent={percent} style={inlineStyles.progress} showInfo={false} strokeColor={AppColors.main} />
    </div>
  );
}
