const supportEmail = 'contact@mediaroi.io';

const fr = {
  mainSiteUrl: 'https://www.mediaroi.io/',
  global: {
    errors: {
      '403': {
        title: 'Non autorisé',
        message: `Désolé, vous n'êtes pas autorisé à accéder à cette page. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
      '404': {
        title: 'Page non trouvée',
        message: `Désolé, la page que vous avez visitée n'existe pas. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
    },
  },
  menu: {
    home: 'Accueil',
    notFound: 'Page non trouvée',
    unauthorized: 'Non autorisé',
  },
  home: {
    title: 'Envoyez vos fichiers de données',
    subtitle:
      "Transférez facilement vos fichiers de données au format Excel ou CSV en les faisant simplement glisser et déposer dans la zone désignée ci-dessous. Ce processus garantit un transfert sécurisé de vos fichiers, vous permettant de partager vos données en toute confiance avec l'équipe de mediaROI.",
    urlSecure:
      "Notre application web est sécurisée avec un chiffrement SSL, comme indiqué par l'icône du cadenas à côté de la barre d'adresse. Cela garantit que vos données sont protégées et que votre connexion à notre plateforme est sécurisée.",
    fileUpload: {
      dragFile: 'Cliquez ou faites glisser des fichiers vers cette zone pour les télécharger',
      hint: "Prise en charge d'un téléchargement unique ou groupé. Types de fichiers autorisés : fichiers Excel ou CSV",
    },
    messages: {
      success: 'Le fichier "{{filename}}" a été téléchargé',
      allSuccess: {
        title: {
          one: 'Votre fichier a été téléchargé avec succès',
          many: 'Vos fichiers ont été téléchargés avec succès',
        },
        subTitle: {
          one: "Merci d'avoir téléchargé votre fichier ! Vous pouvez maintenant fermer cette fenêtre en toute sécurité ou revenir à l'application principale. Nous tenons à vous assurer que notre équipe examinera rapidement votre fichier. Attendez-vous à recevoir des nouvelles de notre part bientôt concernant l'état de votre soumission. Si vous avez d'autres questions ou préoccupations, n'hésitez pas à nous contacter.",
          many: "Merci d'avoir téléchargé vos fichiers ! Vous pouvez maintenant fermer cette fenêtre en toute sécurité ou revenir à l'application principale. Nous tenons à vous assurer que notre équipe examinera rapidement vos fichiers. Attendez-vous à recevoir des nouvelles de notre part bientôt concernant l'état de votre soumission. Si vous avez d'autres questions ou préoccupations, n'hésitez pas à nous contacter.",
        },
        ctaGoToMainApp: 'Retour à Mon Compte',
        ctaUploadAgain: 'Télécharger à nouveau',
      },
      error: 'Une erreur s\'est produite lors du téléchargement du fichier "{{filename}}"',
      noFile: "Il n'y a aucun fichier à télécharger",
    },
  },
};

export default fr;
