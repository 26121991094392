import { t } from '../locales/i18nManager';

const routes = {
  home: {
    key: 'home',
    label: t('menu.home'),
    route: '/',
  },
  notFound: {
    key: 'notFound',
    label: t('menu.notFound'),
    route: '*',
  },
  unauthorized: {
    key: 'unauthorized',
    label: t('menu.unauthorized'),
    route: '/oops',
  },
};
export default routes;
