import { Button, ButtonProps } from 'antd';
import { createUseStyles } from 'react-jss';
import AppColors from '../../config/AppColors';

const useStyles = createUseStyles({
  button: {
    borderColor: AppColors.borders.main,
    borderRadius: 5,
  },
});

export default function MRButton(props: ButtonProps) {
  const styles = useStyles();

  return <Button {...props} className={styles.button} />;
}
