import { Col, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headerOuter: {
    boxShadow: '0 3px 45px rgba(0,0,0,0.15)',
  },
  top: {
    height: 'unset',
    backgroundColor: 'white',
    position: 'relative',
    zIndex: '10',
    width: '100%',
    transition: 'border-color 0.2s ease',
    borderBottom: '1px solid #d2f5f9',
    padding: '0',
  },
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '0 90px',
    position: 'relative',
  },
  row: {
    display: 'flex',
    marginLeft: '0',
    marginRight: '0',
    position: 'relative',
  },
  firstCol: {
    position: 'relative',
    display: 'flex',
    float: 'none',
    width: 'auto',
    marginRight: '25px',
  },
  logo: {
    marginTop: '28px',
    marginBottom: '28px',
    position: 'relative',
    width: 'auto',
    maxWidth: 'none',
    lineHeight: '22px',
    fontSize: '22px',
    letterSpacing: '-1px',
    color: '#444',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    alignSelf: 'center',
  },
  logoImage: {
    height: '30px',
    textIndent: '-9999px',
    maxWidth: 'none',
    width: 'auto',
    marginBottom: '0',
    display: 'block',
  },
  nav: {
    display: 'flex',
  },
  menu: {
    display: 'flex',
    float: 'none',
    verticalAlign: 'middle',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    float: 'left',
    listStyle: 'none',
    outline: '0 none',
    lineHeight: '0',
    fontSize: '12px',
    position: 'relative',
    margin: '0',
    padding: '0',
    zIndex: '10',
  },
  menuItemLink: {
    color: '#020331 !important',
    padding: '0.55555555555556em 1em',
    fontFamily: 'Sora',
    fontSize: '16px',
    lineHeight: '22.4px',
    display: 'block',
    transition: 'color .2s ease',
    outline: '0 none',
    position: 'relative',
    textDecoration: 'none',
    listStyle: 'none outside none',
    margin: '0',
    zIndex: '10',
  },
  menuItemText: {
    transition: 'color .45s cubic-bezier(0.25,1,0.33,1)',
    listStyle: 'none outside none',
    margin: '0',
    padding: '0',
  },
});
export default function NavBar() {
  // Hooks
  const style = useStyles();

  return (
    <div className={style.headerOuter}>
      <Header className={style.top}>
        <div className={style.container}>
          <Row className={style.row}>
            <Col span={3} className={style.firstCol}>
              <a className={style.logo} href="https://www.mediaroi.io">
                <img
                  className={style.logoImage}
                  width="980"
                  height="184"
                  alt="mediaROI"
                  src="https://www.mediaroi.io/wp-content/uploads/2024/01/logo_colors_mediaroi.svg"
                  srcSet="https://www.mediaroi.io/wp-content/uploads/2024/01/logo_colors_mediaroi.svg 1x, https://www.mediaroi.io/wp-content/uploads/2024/01/logo_colors_mediaroi.svg 2x"
                />
              </a>
            </Col>

            {/*
            <Col span={9}>
              <nav className={style.nav}>
                <ul className={style.menu}>
                  <li className={style.menuItem}>
                    <a href="#solutions" className={style.menuItemLink}>
                      <span className="menu-title-text">Solutions</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-16"
                    className="menu-item menu-item-type-custom menu-item-object-custom nectar-regular-menu-item menu-item-16"
                  >
                    <a href="#plateforme">
                      <span className="menu-title-text">Plateforme</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-17"
                    className="menu-item menu-item-type-custom menu-item-object-custom nectar-regular-menu-item menu-item-17"
                  >
                    <a href="#apropos">
                      <span className="menu-title-text">À propos</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
            
            <div className="right-aligned-menu-items">
              <nav>
                <ul className="buttons sf-menu" data-user-set-ocm="off">
                  <li
                    id="menu-item-1483"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1476 current_page_item nectar-regular-menu-item menu-item-1483"
                  >
                    <a href="https://www.mediaroi.io/account/" aria-current="page">
                      <span className="menu-title-text">Mon compte</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-1482"
                    className="menu-item menu-item-type-post_type menu-item-object-page nectar-regular-menu-item menu-item-1482"
                  >
                    <a href="https://www.mediaroi.io/logout/">
                      <span className="menu-title-text">Déconnexion</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
           */}
          </Row>
        </div>
      </Header>
    </div>
  );
}
