import { Image } from 'antd';

export default function Logo() {
  return (
    <Image
      alt="logo"
      height={200}
      width={381}
      preview={false}
      // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
      src={require('../../assets/images/logo.png')}
    />
  );
}
