import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

export type FileUploadProps = {
  uploadRequest: (options: any) => void;
  onAllFilesUploaded: (nbFiles: number) => void;
};

export default function FileUpload(props: FileUploadProps) {
  const { uploadRequest, onAllFilesUploaded } = props;

  // Hooks
  const { t } = useTranslation();

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const allFilesUploaded = info.fileList.reduce((acc, file) => {
      const isFileUploaded = file.status === 'done';
      return acc && isFileUploaded;
    }, true);

    if (allFilesUploaded) {
      onAllFilesUploaded(info.fileList.length);
    }
  };

  return (
    <Dragger
      name="files"
      accept=".xls, .xlsx, .csv"
      multiple
      showUploadList={{
        showDownloadIcon: false,
        showPreviewIcon: false,
        showRemoveIcon: false,
      }}
      customRequest={file => uploadRequest(file)}
      onChange={info => onChange(info)}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('home.fileUpload.dragFile')}</p>
      <p className="ant-upload-hint">{t('home.fileUpload.hint')}</p>
    </Dragger>
  );
}
