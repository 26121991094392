const supportEmail = 'contact@mediaroi.io';

const en = {
  mainSiteUrl: 'https://www.mediaroi.io/',
  global: {
    errors: {
      '403': {
        title: 'Unauthorized',
        message: `Sorry, you are not authorized to access this page. Please contact the support at ${supportEmail}, if you think this is an error.`,
      },
      '404': {
        title: 'Page not found',
        message: `Sorry, the page you visited does not exist. Please contact the support at ${supportEmail}, if you think this is an error.`,
      },
    },
  },
  menu: {
    home: 'Home',
    notFound: 'Not found',
    unauthorized: 'Unauthorized',
  },
  home: {
    title: 'Upload your data files',
    subtitle:
      'Effortlessly upload your data files in Excel or CSV format by simply dragging and dropping them into the designated area below. This process ensures a secure transfer of your files, allowing you to share your data with confidence with the mediaROI team',
    urlSecure:
      'Our web app is secured with SSL encryption, as indicated by the locker icon next to the address bar. This ensures that your data is protected and your connection to our platform is safe.',
    fileUpload: {
      dragFile: 'Click or drag files to this area to upload',
      hint: 'Support for a single or bulk upload. File types allowed: Excel or CSV files',
    },
    messages: {
      success: 'The file "{{filename}}" has been uploaded',
      allSuccess: {
        title: {
          one: 'Your file has been successfully uploaded',
          many: 'Your files has been successfully uploaded',
        },
        subTitle: {
          one: 'Thank you for uploading your file! You can now safely close this window or return to the main application. We want to assure you that our team will promptly review your file. Expect to hear from us soon regarding the status of your submission. If you have any further questions or concerns, feel free to reach out to us.',
          many: 'Thank you for uploading your files! You can now safely close this window or return to the main application. We want to assure you that our team will promptly review your files. Expect to hear from us soon regarding the status of your submission. If you have any further questions or concerns, feel free to reach out to us.',
        },
        ctaGoToMainApp: 'Go back to My Account',
        ctaUploadAgain: 'Upload again',
      },
      error: 'An error occurred while uploading the file "{{filename}}"',
      noFile: 'There is no file to upload',
    },
  },
};

export default en;
