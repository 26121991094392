// eslint-disable-next-line import/no-extraneous-dependencies
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useState } from 'react';

export type LoginStatus = 'idle' | 'loading' | 'authenticated' | 'error';

export default function useLogin() {
  const [status, setStatus] = useState<LoginStatus>('idle');

  async function login() {
    setStatus('loading');

    try {
      const auth = getAuth();
      const result = await signInAnonymously(auth);
      setStatus('authenticated');
      return result;
    } catch (error) {
      setStatus('error');
      return undefined;
    }
  }

  return { status, login };
}
