/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Col, Image, Popover, Result, Row, Typography } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import MRButton from '../components/buttons/MRButton';
import FileUpload from '../components/fields/FileUpload';
import useClientData from '../hooks/useClientData';
import { firebaseStorage } from '../services/Firebase';
import DateUtil from '../utils/DateUtil';

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  urlSecureContainer: {
    paddingBottom: 24,
  },
  urlSecureImage: {
    cursor: 'pointer',
  },
  container: {
    height: '100%',
  },
  allFilesSuccess: {
    margin: '0 auto',
    maxWidth: 800,
    marginTop: 16,
  },
  uploadFileContainer: {
    margin: '0 auto',
    maxWidth: 600,
    height: 250,
    marginTop: 16,
  },
});

export default function Home() {
  // States
  const [allFilesUploaded, setAllFilesUploaded] = useState<number>(-1);

  // Hooks
  const styles = useStyles();
  const { clientData } = useClientData();
  const [notificationApi, notificationContext] = useNotification();
  const { t } = useTranslation();

  const uploadFile = (fileData: any) => {
    if (!(fileData && fileData.file)) {
      notificationApi.error({
        message: t('home.messages.noFile'),
      });
      return;
    }

    // Add date to file name
    const rawFilename = fileData.file.name;
    const filename = `${DateUtil.getReversedShortDateTime()}-${rawFilename}`;
    const filepath = `${clientData}/${filename}`;

    const storageRef = ref(firebaseStorage, filepath);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const uploadTask = uploadBytesResumable(storageRef, fileData.file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        fileData.onProgress({ percent: progress });
      },
      () => {
        notificationApi.error({
          message: t('home.messages.error', { filename: rawFilename }),
        });
      },
      () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        fileData.onSuccess();
        notificationApi.success({
          message: t('home.messages.success', { filename: rawFilename }),
        });
      },
    );
  };

  const popoverUrlCertificate = () => (
    <Image
      alt="url-certificate"
      height={77}
      width={390}
      preview={false}
      // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
      src={require('../assets/images/url-certificate.png')}
    />
  );

  const renderAllFilesUploadedSuccess = () => {
    const textPluralSuffix = allFilesUploaded === 1 ? 'one' : 'many';

    return (
      <Result
        status="success"
        className={styles.allFilesSuccess}
        title={t(`home.messages.allSuccess.title.${textPluralSuffix}`)}
        subTitle={t(`home.messages.allSuccess.subTitle.${textPluralSuffix}`)}
        extra={[
          <MRButton type="primary" href={t('mainSiteUrl')}>
            {t('home.messages.allSuccess.ctaGoToMainApp')}
          </MRButton>,
          <MRButton onClick={() => setAllFilesUploaded(-1)}>{t('home.messages.allSuccess.ctaUploadAgain')}</MRButton>,
        ]}
      />
    );
  };

  return (
    <div className={styles.container}>
      {notificationContext}

      <div className={styles.urlSecureContainer}>
        <Row justify="center" align="middle" gutter={[8, 8]}>
          <Col>
            <Popover content={popoverUrlCertificate()} trigger="hover" placement="bottomLeft" arrow={false}>
              <Image
                className={styles.urlSecureImage}
                alt="url-secure"
                height={57}
                width={249}
                preview={false}
                // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment
                src={require('../assets/images/url-secure.png')}
              />
            </Popover>
          </Col>
          <Col span={12}>
            <Text>{t('home.urlSecure')}</Text>
          </Col>
        </Row>
        <Row justify="center" />
      </div>

      <Title level={3}>{t('home.title')}</Title>
      <Text>{t('home.subtitle')}</Text>

      {allFilesUploaded > 0 ? (
        renderAllFilesUploadedSuccess()
      ) : (
        <div className={styles.uploadFileContainer}>
          <FileUpload
            uploadRequest={file => uploadFile(file)}
            onAllFilesUploaded={nbFiles => setAllFilesUploaded(nbFiles)}
          />
        </div>
      )}
    </div>
  );
}
