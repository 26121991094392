/* eslint-disable import/no-extraneous-dependencies */
import { Layout } from 'antd';
import { child, get, ref as refDatabase } from 'firebase/database';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PageLoader from '../../components/loader/PageLoader';
import AppColors from '../../config/AppColors';
import useClientData from '../../hooks/useClientData';
import useLogin from '../../hooks/useLogin';
import useQuery from '../../hooks/useQuery';
import NavBar from '../../navigation/NavBar';
import { firebaseDatabase } from '../../services/Firebase';
import routes from '../../services/RouteService';

const { Content } = Layout;

export default function Shell() {
  // Hooks
  const navigate = useNavigate();
  const query = useQuery();
  const { status, login } = useLogin();
  const { setClientData } = useClientData();

  const isCodeValid = async (code: string) => {
    const dbRef = refDatabase(firebaseDatabase);
    const snapshot = await get(child(dbRef, `clients/${code}`));
    if (snapshot.exists()) {
      return snapshot.val() as string;
    }
    return undefined;
  };

  useEffect(() => {
    const authenticate = async () => {
      // Get the code from query string params
      const code = query.get('code');

      if (code) {
        // Get the client details from the code
        const client = await isCodeValid(code);

        if (!client) {
          // Code invalid, redirect to unauthorized page
          navigate(routes.unauthorized.route);
        } else {
          const authenticatedUser = await login();

          if (!authenticatedUser) {
            // Code invalid (= no client) or unable to login, redirect to unauthorized page
            navigate(routes.unauthorized.route);
          } else {
            // There is a code, the code is valid and the user is authenticated
            // For now, keep the client data in session storage
            setClientData(client);
          }
        }
      } else {
        // No code, redirect to unauthorized page
        navigate(routes.unauthorized.route);
      }
    };

    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'error') {
      // unable to login, redirect to unauthorized page
      navigate(routes.unauthorized.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === 'idle' || status === 'loading') {
    return <PageLoader />;
  }

  return (
    <Layout style={{ height: '100dvh', backgroundColor: AppColors.background }}>
      <NavBar />
      <Content
        style={{
          paddingLeft: 90,
          paddingRight: 90,
          paddingTop: 55,
          paddingBottom: 55,
          margin: 0,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
}
