import { Layout, Result } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import MRButton from '../../components/buttons/MRButton';
import AppColors from '../../config/AppColors';
import NavBar from '../../navigation/NavBar';

export default function NotFoundPage() {
  // Hooks
  const { t } = useTranslation();

  return (
    <Layout style={{ height: '100dvh', backgroundColor: AppColors.background }}>
      <NavBar />
      <Content
        style={{
          paddingLeft: 90,
          paddingRight: 90,
          paddingTop: 55,
          paddingBottom: 55,
          margin: 0,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Result
          status="404"
          title={t(`global.errors.${404}.title`)}
          subTitle={t(`global.errors.${404}.message`)}
          extra={
            <MRButton type="primary" href={t('mainSiteUrl')}>
              {t('menu.home')}
            </MRButton>
          }
        />
      </Content>
    </Layout>
  );
}
