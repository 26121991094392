const AppColors = {
  // Blue Ultramarine
  main: '#070996',
  // Green Moonstone
  secondary: '#19ACBF',
  borders: {
    main: '#929497',
  },
  colorTextBase: '#000000',
  colorTextDisabled: '#2e2e2e',
  background: 'white',
};
export default AppColors;
